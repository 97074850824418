/* eslint-disable react/no-array-index-key */
import React from 'react'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'

const Content = styled(ParseContent)`
  & h3 {
    font-family: ${(props) => props.theme.font.family.main};
    font-weight: ${(props) => props.theme.font.weight.xl};
    font-size: ${(props) => props.theme.font.size['30']};
    line-height: ${(props) => props.theme.font.size['30']};
    color: ${(props) => props.theme.color.text.dark};
    margin: 0;
  }
`

const StyledImage = styled(Plaatjie)`
  width: 65px;
  height: 70px;

  & img {
    object-fit: contain !important;
  }
`

const USP = styled.div`
  border: 1px solid ${(props) => props.theme.color.face.dark};
`

interface UspProductProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_UspProduct
}

const UspProduct: React.FC<UspProductProps> = ({ fields }) => (
  <section className="container py-lg-5 py-4">
    <div className="row">
      {fields?.usps?.map((usp, index) => (
        <div
          key={index}
          className={`col-lg-4 mb-lg-0 ${index !== 2 ? `mb-4` : ``}`}
        >
          <USP className="py-3 pl-3 d-flex align-items-center">
            <StyledImage className="mr-4" image={usp?.image} alt="" />
            <Content content={usp?.description} />
          </USP>
        </div>
      ))}
    </div>
  </section>
)

export default UspProduct
